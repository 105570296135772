<template>
  <v-container id="BollettaInterattivaHelpTile5Dt" class="pa-7">
    <v-row justify="center" align="center">
       
      <v-card v-for="(tile, i) in tiles" :key="'tiles-' + i"  class="card_fast_action_container" @click="elementAction(tile)">
        <v-row class="fast_title 'Tiles5Dt-' + i" justify="center" align="center">
           <v-col cols="10" justify="center" align="center">
              <p class="title">{{translateLabel(tile['title'])}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img width="100%"  class=".float-right"
                :src="getImg(tile['img'])"/>
            </v-col>
        </v-row> 
      </v-card>

<!-- 
      <v-card class="card_fast_action_container">
        <v-row class="FirstContentBollettaInterattivaHelpTile5Dt " justify="center" align="center" @click="downloadFile">
           <v-col cols="10" justify="center" align="center">
              <p class="area_riservata_title">{{translateLabel('message.bolletta_interattiva_template5_fast_actioins_download_invoice')}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img width="100%"  class=".float-right"
                :src="getImg('/images/bollettainterattiva/Template5/Core/ico-scarica-bollettino.svg')"/>
            </v-col>
        </v-row> 
      </v-card>

      <v-card class="card_fast_action_container">
        <v-row class="FirstContentBollettaInterattivaHelpTile5Dt " justify="center" align="center" @click="showDialog = true">
           <v-col cols="10" justify="center" align="center">
              <p class="area_riservata_title">{{translateLabel('message.bolletta_interattiva_template5_fast_actioins_autolettura')}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img width="100%"  class=".float-right"
                :src="getImg('/images/bollettainterattiva/Template5/Core/ico-contatore.svg')"/>
            </v-col>
        </v-row> 
      </v-card> 

     <v-card class="card_fast_action_container">
        <v-row class="FirstContentBollettaInterattivaHelpTile5Dt " justify="center" align="center" @click="showDialog = true">
           <v-col cols="10" justify="center" align="center">
              <p class="area_riservata_title">{{translateLabel('message.bolletta_interattiva_template5_fast_actioins_autolettura')}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img width="100%"  class=".float-right"
                :src="getImg('/images/bollettainterattiva/Template5/Core/ico-contatore.svg')"/>
            </v-col>
        </v-row> 
      </v-card> 
      
           <v-card class="card_fast_action_container">
        <v-row class="FirstContentBollettaInterattivaHelpTile5Dt " justify="center" align="center" @click="showDialog = true">
           <v-col cols="10" justify="center" align="center">
              <p class="area_riservata_title">{{translateLabel('message.bolletta_interattiva_template5_fast_actioins_autolettura')}}</p>
           </v-col>
            <v-col cols="2" justify="center" align="center">
              <img width="100%"  class=".float-right"
                :src="getImg('/images/bollettainterattiva/Template5/Core/ico-contatore.svg')"/>
            </v-col>
        </v-row> 
      </v-card>  -->

         <!-- <v-dialog v-if="showDialog"
          transition="dialog-top-transition"
          v-model="showDialog" width="50%">      
            <v-card  class="card_home_container autolettura_dialog_card">
                  <v-card-title class="text-h6">                        
                      <v-row class="TitleDialog d-flex" justify="center" align="left" >
                        <v-col cols="1">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-contatore2.svg')" />
                        </v-col>
                        <v-col cols="9" class="">
                            <span class="dialog_title">{{translateLabel('message.bolletta_interattiva_template5_autolettura_dialog_title')}}</span>
                        </v-col>
                        <v-col cols="1" class=".float-end">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-exit.svg')"  @click="showDialog = false" />
                        </v-col>
                      </v-row>
                  </v-card-title>

                <v-card-text class="text-subtitle-1 pa-5 ">

                      <v-row class="FirstTextDialog d-flex" justify="center" align="left" >
                        <v-col cols="6" class="">
                          <p class="autoletturatile_dialog_text overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_autolettura_dialog_text")'></p>
                        </v-col>
                        <v-col cols="6">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/autolettura-image.svg')" />
                        </v-col>
                      </v-row>
                      <v-form
                          ref="form"
                          v-model="valid"
                        >
                          <v-row >
                          <v-text-field 
                          v-model="lettura_val" outlined
                          :rules="[v=> checkAutolettura(v)]"
                          >
                          </v-text-field>
                      </v-row>
                      </v-form>

                      <v-row>
                        <v-btn
                            style="border-radius: 8px !important;"
                            class="dialog_autolettura_save_button" 
                            @click="Save()"                         
                            >
                            {{$t('message.bolletta_interattiva_template5_autolettura_save')}}          
                        </v-btn>
                      </v-row>

                </v-card-text>
              </v-card>
          </v-dialog> -->
   
      <BollettaInterattivaAutoletturaDialog5Dt :showDialog="showDialog" @close="showDialog=false" :translateLabel="translateLabel" :getImg="getImg"/> 

    </v-row> 
  </v-container>
</template>

<script>
import { services } from '../../../scripts/services/serviceBuilder'
import BollettaInterattivaAutoletturaDialog5Dt from './BollettaInterattivaAutoletturaDialog5Dt.vue';

export default {
 data() {
    return {
        showDialog : false,
        tiles:[]
      }
  },
  components: {
    BollettaInterattivaAutoletturaDialog5Dt
  },
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
    mounted:function(){
      this.init();
    },
  methods:{
    init(){
      if(this.documentData.tipo_bolletta==='GAS')
      this.tiles = [ 
                        {
                          title: 'message.bolletta_interattiva_template5_help_guida_alla_bolletta_text',
                          img: "/images/bollettainterattiva/Template5/Core/ico_guida.svg", 
                          action: "openurl",
                          url: "https://www.unoenergy.it/info/guida-alla-bolletta/"
                        },
                         {
                          title: 'message.bolletta_interattiva_template5_help_contattaci_text',
                          img: "/images/bollettainterattiva/Template5/Core/ico_contattaci.svg", 
                          action: "openurl",
                          url: "https://www.unoenergy.it/contatti/"
                        },
                         {
                          title: 'message.bolletta_interattiva_template5_help_FAQ_text',
                          img: "/images/bollettainterattiva/Template5/Core/ico_i.svg",
                          action: "openurl",
                          url: "https://www.unoenergy.it/serve-aiuto/"
                        },
                         {
                          title: 'message.bolletta_interattiva_template5_help_autolettura_text',
                          img: "/images/bollettainterattiva/Template5/Core/ico_contatore.svg",
                          action: "autolettura",
                          url: null 
                        }
        ]
        else
              this.tiles = [ 
                        {
                          title: 'message.bolletta_interattiva_template5_help_guida_alla_bolletta_text',
                          img: "/images/bollettainterattiva/Template5/Core/ico_guida.svg", 
                          action: "openurl",
                          url: "https://www.unoenergy.it/info/guida-alla-bolletta/"
                        },
                         {
                          title: 'message.bolletta_interattiva_template5_help_contattaci_text',
                          img: "/images/bollettainterattiva/Template5/Core/ico_contattaci.svg", 
                          action: "openurl",
                          url: "https://www.unoenergy.it/contatti/"
                        },
                         {
                          title: 'message.bolletta_interattiva_template5_help_FAQ_text',
                          img: "/images/bollettainterattiva/Template5/Core/ico_i.svg",
                          action: "openurl",
                          url: "https://www.unoenergy.it/serve-aiuto/"
                        }
        ]

    },
        downloadFile: function() {
        var that = this;

        services.apiCallerMedia.download(that.documentData.detail)
          .then(response =>  {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'file.pdf');
              document.body.appendChild(link);
              link.click();
          })               
          .catch(error => {
              console.log(error);
          });
        },
        autolettura: function(){
        },
        elementAction: function(sel) {
          if(sel['action'] == "openurl" && sel['url'].length>0)
            window.open(sel['url'], '_blank');
          else
            if(sel['action'] == "autolettura")
              this.showDialog=true;
        }
  }

}
</script>

<style>

</style>