<template>
    <v-container id="Bolletta_Interattiva_Riepilogo_Doc5Dt" >
        <v-row class="MainRows">
            <v-col cols="10"> 
                <v-container class="wrapWidth ContainerBolletta_Interattiva_Riepilogo_Doc5Dt">
                    <template >
                        <v-row class="FirstContentBolletta_Interattiva_Riepilogo_Doc5Dt" justify="center" align="center" v-if="!isMobile">
                            <v-col cols="6">
                                <div class="invoices_holderName" v-if="documentData.holder_name && checkInArray('invoices_holder_name')">
                                    <h1 class="invoices_holderName_label">{{$t('message.bolletta_interattiva_template5_holder_name')}}</h1>&nbsp;
                                    <h1 class="invoices_holderName">{{valueInArray('invoices_holder_name')}}</h1>
                                </div>
                            </v-col>  
                            <v-col cols="6">  
                                <v-row class="tw-box-laterale tw-box-desktop" v-if="checkInArray('invoices_codice_contratto') || checkInArray('invoices_n_avviso') || checkInArray('invoices_data_emissione')">
                                    <v-col cols="3">
                                        <img :src="documentData.tipo_bolletta == 'GAS' ? getImg('/images/bollettainterattiva/Template5/Core/ico-gas.svg') : getImg('/images/bollettainterattiva/Template5/Core/ico-luce.svg')" />
                                    </v-col>
                                    <v-col cols="8" class="tw-80 tw-m1 tw-dettaglio-box-1 ">
                                        <div class="invoices_n_contract" v-if="checkInArray('invoices_codice_contratto')">
                                            <span class="invoices_n_contract_label">{{$t('message.bolletta_interattiva_template5_n_contract')}}</span>&nbsp;
                                            <span style="font-weight:bold;" class="invoices_n_contract">{{valueInArray('invoices_codice_contratto')}}</span>
                                        </div>
                                        <div class="invoices_n_invoice" v-if="checkInArray('invoices_n_avviso')">
                                            <span class="invoices_n_invoice_label">{{$t('message.bolletta_interattiva_template5_n_invoice')}}</span>&nbsp;
                                            <span style="font-weight:bold;" class="invoices_n_invoice">{{valueInArray('invoices_n_avviso')}}</span>
                                        </div>
                                        <div class="invoices_invoice_date"  v-if="checkInArray('invoices_data_emissione') && documentData.document_date">
                                            <span class="invoices_invoice_date_label">{{$t('message.bolletta_interattiva_template5_invoice_date')}}</span>&nbsp;
                                            <span style="font-weight:bold;" class="invoices_invoice_date">{{documentData.document_date}}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="FirstContentBolletta_Interattiva_Riepilogo_Doc5Dt Mobile"  justify="center" align="center" v-else>
                                <v-row class="tw-box-laterale tw-box-desktop" v-if="checkInArray('invoices_codice_contratto') || checkInArray('invoices_n_avviso') || checkInArray('invoices_data_emissione')">
                                    <v-col cols="2">
                                        <img :src="documentData.tipo_bolletta == 'GAS' ? getImg('/images/bollettainterattiva/Template5/Core/ico-gas.svg') : getImg('/images/bollettainterattiva/Template5/Core/ico-luce.svg')" />
                                    </v-col>
                                    <v-col cols="10" class="tw-80 tw-m1 tw-dettaglio-box-1 ">
                                        <div class="invoices_n_contract" v-if="checkInArray('invoices_codice_contratto')">
                                            <span class="invoices_n_contract_label">{{$t('message.bolletta_interattiva_template5_n_contract')}}</span>&nbsp;
                                            <span style="font-weight:bold;" class="invoices_n_contract">{{valueInArray('invoices_codice_contratto')}}</span>
                                        </div>
                                        <div class="invoices_n_invoice" v-if="checkInArray('invoices_n_avviso')">
                                            <span class="invoices_n_invoice_label">{{$t('message.bolletta_interattiva_template5_n_invoice')}}</span>&nbsp;
                                            <span style="font-weight:bold;" class="invoices_n_invoice">{{valueInArray('invoices_n_avviso')}}</span>
                                        </div>
                                        <div class="invoices_invoice_date"  v-if="checkInArray('invoices_data_emissione') && documentData.document_date">
                                            <span class="invoices_invoice_date_label">{{$t('message.bolletta_interattiva_template5_invoice_date')}}</span>&nbsp;
                                            <span style="font-weight:bold;" class="invoices_invoice_date">{{documentData.document_date}}</span>
                                        </div>
                                    </v-col>
                                </v-row>

                                <div class="invoices_holderName" v-if="documentData.holder_name && checkInArray('invoices_holder_name')">
                                    <h1 class="invoices_holderName_label">{{$t('message.bolletta_interattiva_template5_holder_name')}}</h1>&nbsp;
                                    <h1 class="invoices_holderName">{{valueInArray('invoices_holder_name')}}</h1>
                                </div>
                                
                        </v-row>
                        <v-row  justify="center" align="center" :class="isMobile?  ' monocol Mobile SecondContentBolletta_Interattiva_Riepilogo_Doc5Dt' : 'col DESKTOP SecondContentBolletta_Interattiva_Riepilogo_Doc5Dt'" >
                            <v-col>  
                                <v-row class="tw-box-laterale tw-box-desktop" v-if="checkHolderAddress()">
                                    <v-col cols="3">
                                        <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-pin.svg')"/>
                                    </v-col>
                                    <v-col cols="8" >
                                        <div class="invoices_address" >
                                            <div class="invoices_address_label">{{$t('message.bolletta_interattiva_template5_address')}}</div>
                                            <div style="font-weight:bold;" class="invoices_address">{{getHolderAddress()}}</div>
                                            <div style="font-weight:bold;" class="invoices_address">{{getHolderAddress2()}}</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col>  
                                <v-row class="tw-box-laterale tw-box-desktop" v-if="checkInArray('invoices_codice_cliente')">
                                    <v-col cols="3" >
                                        <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-codice-utenza.svg')"/>
                                    </v-col>
                                    <v-col cols="8" >
                                    <div class="invoices_n_customer">
                                            <span class="invoices_n_customer">{{$t('message.bolletta_interattiva_template5_customer_code')}}</span>&nbsp;
                                            <span style="font-weight:bold;" class="invoices_n_customer">{{valueInArray('invoices_codice_cliente')}}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>  
                    </template>
                </v-container>
            </v-col>
            <v-col cols="2">
                <img :src="documentData.tipo_bolletta == 'GAS' ? getImg('/images/bollettainterattiva/Template5/Core/bg-gas.svg') : getImg('/images/bollettainterattiva/Template5/Core/bg-luce.svg')"/>
            </v-col> 
        </v-row>    
    </v-container>
</template>

<script>
 
export default ({
    data() {
        return {
            imageExists: true,
            userImageExists: true
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: { 
        holderCapLocProv() {
            if (this.documentData.holder_cap || this.documentData.holder_localita || this.documentData.holder_provincia) {
                return this.documentData.holder_cap + ' ' + this.documentData.holder_localita + ' (' + this.documentData.holder_provincia + ')'
            }
            return null;
        }, 
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    methods: {
        getServiceLogo: function() {
            if (this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio) {
                return './' + location.hostname + '/images/bollettainterattiva/' + this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio;
            }
            else {
                this.imageExists = false;
            }
        },
        getUserImage: function() {
            return './' + location.hostname + '/images/bollettainterattiva/ProfileIcon.png';
        },
        imageError: function() {
            this.imageExists = false;
        },
        userImageError: function() {
            this.userImageExists = false;
        },
        checkInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            return Array.from(this.documentData.index_fields).some(item => item.label.toUpperCase() === field_name.toUpperCase());
        },
        valueInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            let field = Array.from(this.documentData.index_fields).find(item => item.label === field_name);
            return (field.value)?field.value:'';
        },
        checkHolderAddress: function ()
        {
         return (this.getHolderAddress() != null || this.getHolderAddress2() != null);
        },
        getHolderAddress: function ()
        {
         return (this.documentData.holder_ind_riga2 ? this.documentData.holder_ind_riga2 + ' ' : '') 
                + (this.documentData.holder_ind_riga3 ? this.documentData.holder_ind_riga3 + ' ' : '') 
                + (this.documentData.holder_toponimo ? this.documentData.holder_toponimo + ' ' : '') 
                + (this.documentData.holder_cap ? '- ' + this.documentData.holder_cap : '');
        },
        getHolderAddress2: function ()
        {
         return (this.documentData.holder_localita ? this.documentData.holder_localita + ' ' : '') 
                + (this.documentData.holder_provincia ? this.documentData.holder_provincia : '');
        },
        getImg(imageName) {
            return "./" + location.hostname + imageName;
        },
    },
})
</script>

