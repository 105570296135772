<template>
  <v-container id="BollettaInterattivaMieiDati5Dt" class="pa-7">
    <v-card class="card_home_container">
      <h3 class="miei_dati_title">{{ $t('message.bolletta_interattiva_template5_miei_dati_title') }}</h3>
      <v-row class="ContentBollettaInterattivaMieiDati5Dt d-flex" justify="left" align="left">

        <div class="invoices_holderName" v-if="documentData.holder_name && checkInArray('invoices_holder_name')">
            <span class="invoices_holderName_label">{{$t('message.bolletta_interattiva_template5_nome_cliente')}}</span>&nbsp;
            <p style="font-weight:bold;" class="invoices_holderName">{{valueInArray('invoices_holder_name')}}</p>
        </div>

        <div class="invoices_address" >
            <div class="invoices_address_label">{{$t('message.bolletta_interattiva_template5_address')}}</div>
            <div v-for="(fornit, i) in documentData.fatt_interattiva_data.fornitura" :key="'fornitura-' + i">
              <div style="font-weight:bold;" class="invoices_address"> {{fornit.indirizzo.join(' ')}}</div>
            </div>
        </div>
       

        <div class="invoices_CF"  v-if="checkInArray('invoices_CF')">
            <span class="invoices_CF_label">{{$t('message.bolletta_interattiva_template5_CF')}}</span>&nbsp;
            <p class="invoices_holderName">{{valueInArray('invoices_CF')}}</p>
        </div>

      </v-row>
    </v-card>
  </v-container>
</template>
<script>

export default {

  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
    checkInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            return Array.from(this.documentData.index_fields).some(item => item.label.toUpperCase() === field_name.toUpperCase());
        },
        valueInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            let field = Array.from(this.documentData.index_fields).find(item => item.label === field_name);
            return (field.value)?field.value:'';
        },
        getHolderAddress: function ()
        {
         return (this.documentData.holder_ind_riga2 || this.documentData.holder_ind_riga3 || this.documentData.holder_provincia) ? 
                (this.documentData.holder_ind_riga2 ? this.documentData.holder_ind_riga2 : '') + (this.documentData.holder_ind_riga3 ? ' ' + this.documentData.holder_ind_riga3 : '') + (this.documentData.holder_cap ? ' - ' + this.documentData.holder_cap : '') : + (this.documentData.holder_provincia ? ' ' + this.documentData.holder_provincia : '') ;
        },
  }

}
</script>

<style>

</style>