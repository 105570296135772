import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from '../store/index.js'
import { services } from "../scripts/services/serviceBuilder";
import HostSettingsService from "../scripts/services/hostSettingsService.js";
import isMobile from "../helpers/user-agent"

Vue.use(VueRouter)

const routerOptions = [
  { path: "/", component: "Home", meta: { isAuthenticated: true } },
  { path: "/home", component: "Home", meta: { isAuthenticated: true } },
  { path: "/invoices", component: "Documents", meta: { isAuthenticated: true } },
  { path: "/contracts", component: "Contracts", meta: { isAuthenticated: true } },
  { path: "/modif_data_user", component: "MyProfile", props: true, meta: { isAuthenticated: true } },
  { path: "/user_form", component: "UserForm", props: true, meta: { isAuthenticated: true } },
  { path: "/contacts", component: "ContactUs", meta: { isAuthenticated: true } },
  { path: "/self", component: "Faq", meta: { isAuthenticated: true } },
  { path: "/register", component: "Register", meta: { isAuthenticated: false } },
  { path: "/messages", component: "Messages", meta: { isAuthenticated: true } },
  { path: '*', component: "MultiModule", meta: { isAuthenticated: true } },
  { path: '/bollettainterattiva', component: "BollettaInterattiva", meta: { isAuthenticated: true, crypto: "id", template: "bollettaInterattivaTemplateName" } },
  { path: '/nexiEsito', component: "NexiEsito", meta: { isAuthenticated: true, crypto: "id", template: "nexiLanding" } },
  { path: '/nexiAnnullo', component: "NexiAnnullo", meta: { isAuthenticated: true, crypto: "id", template: "nexiLanding" }},
  { path: '/:argument', name: 'multimodule', component: "MultiModule", meta: { isAuthenticated: true } },
];

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`../components/${route.component}.vue`)
  };
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach(async (to, from, next) => {

  if(!Vue.prototype.$hostSettings)
  Vue.prototype.$hostSettings = await HostSettingsService.getSettings().then(res => Vue.prototype.$hostSettings = res);

  //set logged_in flag
  if (to.meta.isAuthenticated && Vue.$keycloak.authenticated)
    store.commit("SET_READY", true);
  else
    store.commit("SET_READY", false);

  console.debug('Vue.prototype.$hostSettings found in router: ',Vue.prototype.$hostSettings)
  //set the template
  if(Vue.prototype.$hostSettings != null) {
    console.debug('Found Vue.prototype.$hostSettings !== null', Vue.prototype.$hostSettings)
    var layout = Vue.prototype.$hostSettings.templateName;
    if(to.meta.template != null && 
      Vue.prototype.$hostSettings[to.meta.template] != null) {
      layout = Vue.prototype.$hostSettings[to.meta.template];
      if (layout === 'TemplateBollettaInterattiva3' && !isMobile.check()) {
        console.debug('Switching to desktop template');
        if(Vue.prototype.$hostSettings['bollettaInterattivaDesktopTemplateName'] != null)
        {
          console.debug('bollettaInterattivaDesktopTemplateName found');
          layout =  Vue.prototype.$hostSettings['bollettaInterattivaDesktopTemplateName'];
        }
      }
      if (layout === 'TemplateBollettaInterattiva5' && !isMobile.check()) {
        console.debug('Switching to desktop template');
        if(Vue.prototype.$hostSettings['bollettaInterattivaDesktopTemplateName'] != null)
        {
          console.debug('bollettaInterattivaDesktopTemplateName found');
          layout =  Vue.prototype.$hostSettings['bollettaInterattivaDesktopTemplateName'];
        }
      }
    }
    store.commit("SET_LAYOUT", layout);
  }

  //if there is a crypto in the query
  if (to.meta.crypto != null && to.query[to.meta.crypto] != null) {
    
    if(!Vue.$keycloak.authenticated)
    {
      //build the query string
      var str="";
      for (const [key, value] of Object.entries(to.query)) {
        str += key + '=' + value + '&';
      }
      str = str.substring(0, str.length-1);

      await services.apiCallerPublicAccess.publicAccess(str)
      .then(res => {
        Vue.prototype.$isAuthenticated.value = true;
        Vue.prototype.$tokenFromCrypto.value = true;
        Vue.$keycloak.flow = "implicit";
        Vue.$keycloak.token = res.data.token;
        Vue.$keycloak.authenticated = true;
        Vue.$keycloak.tokenParsed = { locale: res.data.lang };
        Vue.$keycloak.isTokenExpired = function() { return false; };
        Vue.$keycloak.login = function(something) {console.debug("authenticaed by cryptolink"); console.debug(something);};
      })
      .catch(error => {
        if (error.response) {
          switch(error.response.status)
          {
            case 404:
            case 410:
              to.query.error = error.response.status;
              break;
            default:
              console.log(error.response.status);
              break;
          }
        }
      });
    }

    store.commit("SET_READY", true);
    next();
  }
  else{
    Vue.prototype.$tokenFromCrypto.value = false;
    store.commit("SET_READY", true);
    authenticate(to, next);
  }
});

function authenticate(to, next) {
  console.log("entro in authenticate");
  if (to.meta.isAuthenticated) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = window.location.toString()
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      let targetPath = basePath;
      let origin = window.location.origin;

      if ((origin + to.fullPath) != basePath) {
        targetPath = origin + to.fullPath;
      }
      
      Vue.$keycloak.login({ redirectUri: targetPath });
    } else {
    //} else if (Vue.$keycloak.hasResourceRole('vue-koine-user')) {
    // The user was authenticated, and has the app role (is authorized). Check/Update the token.
      // if (Vue.$keycloak.flow != 'implicit') {
      //   Vue.$keycloak.updateToken(70)
      //     .then(() => {
      //       next()
      //     })
      //     .catch(err => {
      //       console.log('Token update failed, redirect to login ...' + err);
      //       next({ path: '/' })
      //     })
      // }
      if (!Vue.$keycloak.isTokenExpired()) {
        Vue.prototype.$isAuthenticated.value = to.meta.isAuthenticated;
        next();
      }
    //} else {
    // The user was authenticated, but did not have the correct role (is not authorized)
    // Redirect the user to home->login page
    // next({ name: 'Home' })
    }
  }
  else {
    Vue.prototype.$isAuthenticated.value = to.meta.isAuthenticated;
    next();
  } 
}

export default router