<template>
	<v-app>
		<v-overlay v-if="!overlayVisible" :value="overlay" class="d-flex flex-column">
			<v-progress-circular  indeterminate size="100"/>
        </v-overlay>
		<div v-else>
			<component :showsite="showsite" v-bind:is="template"></component>
			<cookie-consent v-if="template !== 'TemplateBollettaInterattiva3' && template !== 'TemplateBollettaInterattiva3Dt' && template !== 'TemplateLandingNexi'" id="cookieConsent" buttonLabel="Accetta" linkLabel="">
				<template slot="message">
					<span v-html="cookieBody"></span>
				</template>
			</cookie-consent>
		</div>
	</v-app>
</template>

<script>
import Vue from 'vue';
import Template1 from "./templates/Template1.vue";
import Template2 from "./templates/Template2.vue";
import TemplateBollettaInterattiva from "./templates/TemplateBollettaInterattiva.vue";
import TemplateBollettaInterattiva2 from "./templates/TemplateBollettaInterattiva2.vue";
import TemplateBollettaInterattiva3 from "./templates/TemplateBollettaInterattiva3.vue";
import TemplateBollettaInterattiva4 from "./templates/TemplateBollettaInterattiva4.vue";
import TemplateBollettaInterattiva3Dt from "./templates/TemplateBollettaInterattiva3Dt.vue";
import TemplateBollettaInterattiva5Dt from "./templates/TemplateBollettaInterattiva5Dt.vue";
import TemplateBollettaInterattiva5 from "./templates/TemplateBollettaInterattiva5.vue";
import TemplateLandingNexi from './templates/TemplateLandingNexi.vue';
import CookieConsent from 'vue-cookieconsent-component'
import isMobile from "./helpers/user-agent"

export default Vue.extend({
	name: "App",
	components: {
		Template1,
		Template2,
		TemplateBollettaInterattiva,
		TemplateBollettaInterattiva2,
		TemplateBollettaInterattiva3,
		TemplateBollettaInterattiva4,

		TemplateBollettaInterattiva3Dt,
		TemplateBollettaInterattiva5Dt,
		TemplateBollettaInterattiva5,

		TemplateLandingNexi,
		CookieConsent
	},
	data: () => ({
		keycloak: null,
		initOptions: null,
		overlay: true,
		cookieBody: "",
		cookieBodyVisible: false,
		initInterf: false
	}),
	computed: {
		overlayVisible: function() {
			return this.$store.getters.ready;
		},
		template: function () {
			return this.$store.getters.layout;
		},
		showsite: function () {
			return Object.prototype.hasOwnProperty.call(this.keycloak, 'idTokenParsed');
		}
	},
	methods: {
		initsWithAuth: function() {
            this.keycloak = Vue.$keycloak;
            this.loadTemplateInfo();
		},
		loadTemplateInfo: function() {
			this.$root.$on("logout", this.logout);
			this.setCustomerCSS(location.hostname);
			this.setFavicon(location.hostname);
			this.setTitle(this.$t("message.header_title"));
			this.cookieBody = this.$t("message.cookie_banner_text");
		},
		logout: function() {
			var logoutOptions = { redirectUri : window.location.origin };
			this.keycloak.logout(logoutOptions);
		},
		removeKcSessionCookies: function() {
            document.cookie = 'AUTH_SESSION_ID=; Path=/auth/realms/' + this.keycloak.realm + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
            document.cookie = 'AUTH_SESSION_ID_LEGACY=; Path=/auth/realms/' + this.keycloak.realm + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
            document.cookie = 'KC_RESTART=; Path=/auth/realms/' + this.keycloak.realm + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
		},
		setCustomerCSS: function (customerName) {
			var element = document.createElement("link");
			element.setAttribute("rel", "stylesheet");
			element.setAttribute("type", "text/css");
			element.setAttribute("href", customerName + "/styles/style.css");
			document.getElementsByTagName("head")[0].appendChild(element);
		},
		setCustomerCSSTemplate: function (customerName) {
			let elementTemplate = document.createElement("link");
			elementTemplate.setAttribute("rel", "stylesheet");
			elementTemplate.setAttribute("type", "text/css");
			(isMobile.check()) ? elementTemplate.setAttribute("href", customerName + "/styles/mobile.css") : elementTemplate.setAttribute("href", customerName + "/styles/desktop.css")
			
			document.getElementsByTagName("head")[0].appendChild(elementTemplate);

		},
		setFavicon: function (customerName) {
			var element = document.getElementById("favicon");
			element.href = customerName + "/images/favicon.png";
		},
		setTitle: function (pagetitle) {
			document.title = pagetitle;
		},
    includeCookieBannerScript: function () {
      console.debug("Including Cookie Banner script");
      const cBannerScript = document.createElement("script");
      cBannerScript.setAttribute("charset", "UTF-8");
      cBannerScript.setAttribute("async", "");
      //cBannerScript.defer = true;
      cBannerScript.setAttribute(
          "src",
          "//cdn.iubenda.com/cs/iubenda_cs.js"
      );
      cBannerScript.setAttribute("type", "application/javascript");
      document.head.appendChild(cBannerScript);
		const iub = document.createElement("script");
	iub.appendChild(document.createTextNode(
		'var _iub = _iub || [];_iub.csConfiguration = {"enableRemoteConsent":true,"floatingPreferencesButtonCaptionColor":"#737373","floatingPreferencesButtonColor":"#f4f4f4","floatingPreferencesButtonDisplay":"bottom-left","floatingPreferencesButtonHover":true,"perPurposeConsent":true,"siteId":2456414,"whitelabel":false,"cookiePolicyId":13955057,"lang":"it","cookiePolicyUrl":"https://dufercoenergia.com/informativa-cookie/","floatingPreferencesButtonCaption":true, "banner":{ "acceptButtonCaptionColor":"white","acceptButtonColor":"#f28800","acceptButtonDisplay":true,"backgroundColor":"#202020","closeButtonDisplay":false,"customizeButtonCaptionColor":"white","customizeButtonColor":"#3b3b3b","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonCaptionColor":"white","rejectButtonColor":"#f28800","rejectButtonDisplay":true,"textColor":"white" }};'
	));
      iub.setAttribute("type", "text/javascript");
      document.head.appendChild(iub);
    },

	},
  beforeUpdate() {
	if(!this.initInterf)
	{
		if (this.template === 'TemplateBollettaInterattiva3' || this.template === 'TemplateBollettaInterattiva3Dt' || this.template === 'TemplateLandingNexi') {
		this.includeCookieBannerScript();
		}
		this.setCustomerCSSTemplate(location.hostname);
		this.initInterf=!this.initInterf;
	}
  },
  created: function () {
		this.initsWithAuth();
	},
});
</script>

<style lang="scss">
	@import "vue-cookieconsent-component/src/scss/cookie-consent";
</style>


