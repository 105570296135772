<template>
  <v-container id="BollettaInterattivaWebTile5Dt" class="pa-7">
    <v-row  justify="center" align="center">
      <v-card class="card_home_container">
        <v-row class="FirstContentBollettaInterattivaWebTile5Dt " justify="left" align="left" >
            <v-col cols="2">
              <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-foglia.svg')" />
            </v-col>
            <v-col cols="10" class="">
                <span class="invoices_n_contract_label">{{translateLabel('message.bolletta_interattiva_template5_webText')}}</span>
            </v-col>
        </v-row>                     
        <v-row class="SecondContentBollettaInterattivaWebTile5Dt d-flex" justify="center" align="center" >
          <v-btn
              class="bollettaInterattiva5_webButton white--text"
                @click="showDialog = true"
                x-large
                color="orange"
                >
                <span class="bollettaInterattiva5_webButton_content"
                 v-html="$t('message.bolletta_interattiva_template5_web_button_label')"></span> 
          </v-btn>
        </v-row>

        <!-- <v-dialog v-if="showDialog"
          transition="dialog-top-transition"
          v-model="showDialog" width="70%">      
            <v-card  class="card_home_container webtile_dialog_card">
                  <v-card-title class="text-h6">                        
                      <v-row class="TitleDialog d-flex" justify="center" align="left" >
                        <v-col cols="1">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-foglia2.svg')" />
                        </v-col>
                        <v-col cols="9" class="">
                            <span class="dialog_title">{{translateLabel('message.bolletta_interattiva_template5_webtile_dialog_title')}}</span>
                        </v-col>
                        <v-col cols="1" class=".float-end">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-exit.svg')"  @click="showDialog = false" />
                        </v-col>
                      </v-row>
                  </v-card-title>

                <v-card-text class="text-subtitle-1 pa-5 ">

                      <v-row class="FirstTextDialog d-flex" justify="center" align="left" >
                        <v-col cols="6" class="">
                          <p class="webtile_dialog_text overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_webtile_dialog_text")'></p>
                        </v-col>
                        <v-col cols="6">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/image-bolletta-web.svg')" />
                        </v-col>
                      </v-row>
                      <v-form
                          ref="form"
                          v-model="valid"
                        >
                          <v-row >
                          <v-text-field v-bind:label="'Indirizzo email'" 
                          v-model="profiloDigitale.email" outlined
                          v-bind:prepend-inner-icon="getEmailIcon()"
                          :rules="[v=> checkEmail(v)]"
                          >
                          </v-text-field>
                      </v-row>
                      <v-row >
                          <v-text-field v-bind:label="'Conferma email'" 
                          v-model="profiloDigitale.confirmEmail" outlined
                          v-bind:prepend-inner-icon="getConfirmEmailIcon()"
                          :rules="[v=> checkConfirmEmail(v)]"
                          >
                          </v-text-field>
                      </v-row>
                      <v-row>
                        <v-checkbox
                          v-model="privacy_sel"
                          :rules="[checkPrivacy()]"
                        >
                        <template v-slot:label>
                            <span class="dialog_privacy"
                            v-html="$t('message.bolletta_interattiva_template5_webtile_dialog_privacy')"></span> 
                        </template>
                        </v-checkbox>
                      </v-row>   
                     
                      </v-form>

                      <v-row>
                        <v-btn
                            :disabled="enabledSave"
                            style="border-radius: 8px !important;"
                            class="dialog_web_save_button" 
                            @click="Save()"                         
                            >
                            {{$t('message.bolletta_interattiva_duferco_profilo_salva')}}          
                        </v-btn>
                      </v-row>

                </v-card-text>
              </v-card>
          </v-dialog> -->

          
        <BollettaInterattivaWebTileDialog5Dt :showDialog="showDialog" @close="showDialog=false" :translateLabel="translateLabel" :getImg="getImg"/> 

      </v-card>
    </v-row> 
  </v-container>
</template>

<script>
import BollettaInterattivaWebTileDialog5Dt from './BollettaInterattivaWebTileDialog5Dt.vue';

export default {

  data() {
    return {
        showDialog : false
       
  }
  },
  components: {
    BollettaInterattivaWebTileDialog5Dt
  },
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
  }

}
</script>

<style>

</style>