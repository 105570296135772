<template>
    <v-container id="Bolletta_Interattiva_Riepilogo_Doc3Dt" >
        <v-row class="MainRows">
            <div class="monocol sectionParteSuperiore">
                <v-list-item three-line class="justify-center"  style="text-align:left;">
                    <!--v-list-item-avatar v-if="userImageExists">
                        <v-img class="holderImage" :src="getUserImage()" @error="userImageError"/>
                    </v-list-item-avatar-->
                    <v-list-item-content>
                        <v-list-item v-if="checkInArray('invoices_codice_cliente')">
                            <div class="invoices_codice_cliente">
                                <span class="invoices_codice_cliente_label">{{$t('message.bolletta_interattiva_codice_cliente')}}:</span>&nbsp;
                                <span class="invoices_codice_cliente">{{valueInArray('invoices_codice_cliente')}}</span>
                            </div>
                        </v-list-item>

                        <v-list-item v-if="documentData.holder_name && checkInArray('invoices_holder_name')">
                            <div class="invoices_holderName">
                                <span class="invoices_holderName_label">{{$t('message.bolletta_interattiva_holder_name')}}:</span>&nbsp;
                                <span class="invoices_holderName">{{documentData.holder_name}}</span>
                            </div>
                        </v-list-item>
                        <v-col v-if="imageExists" class="sectionServiceImage">
                            <div class="imageContainer">
                                <v-img contain class="serviceImage" :src="getServiceLogo()" @error="imageError" />
                            </div>
                        </v-col>
                        <v-list-item v-if="checkInArray('invoices_n_avviso') && documentData.document_number">
                            <div class="invoices_n_avviso">
                                <span class="invoices_n_avviso_label">{{$t('message.bolletta_interattiva_fatt_n_doc_label')}}</span>&nbsp;
                                <span class="invoices_n_avviso">{{documentData.document_number}}</span>
                            </div>
                        </v-list-item>

                        <v-list-item v-if="checkInArray('invoices_data_emissione') && documentData.document_date">
                                    <div class="invoices_data_emissione">
                                        <span class="invoices_data_emissione_label">{{$t('message.bolletta_interattiva_invoices_data_emissione')}}</span>&nbsp;
                                        <span class="invoices_data_emissione">{{documentData.document_date}}</span>
                                    </div>
                        </v-list-item>              
                        <v-list-item v-if="(documentData.bolletta_interattiva_esponi_periodo_fatturazione == 1) && documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio">
                                    <div class="descrizione_servizio">
                                        <span class="periodo_fatturazione_label">{{$t('message.bolletta_interattiva_label_periodo_fatturazione')}}:</span>&nbsp;
                                        <span class="periodo_fatturazione">{{documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio}}</span>
                                    </div>
                        </v-list-item>                       
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-row>    
    </v-container>
</template>

<script>
 
export default ({
    data() {
        return {
            imageExists: true,
            userImageExists: true
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: { 
        holderCapLocProv() {
            if (this.documentData.holder_cap || this.documentData.holder_localita || this.documentData.holder_provincia) {
                return this.documentData.holder_cap + ' ' + this.documentData.holder_localita + ' (' + this.documentData.holder_provincia + ')'
            }
            return null;
        }        
    },
    methods: {
        getServiceLogo: function() {
            if (this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio) {
                return './' + location.hostname + '/images/bollettainterattiva/' + this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio;
            }
            else {
                this.imageExists = false;
            }
        },
        getUserImage: function() {
            return './' + location.hostname + '/images/bollettainterattiva/ProfileIcon.png';
        },
        imageError: function() {
            this.imageExists = false;
        },
        userImageError: function() {
            this.userImageExists = false;
        },
        checkInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            return Array.from(this.documentData.index_fields).some(item => item.label.toUpperCase() === field_name.toUpperCase());
        },
        valueInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            return Array.from(this.documentData.index_fields).find(item => item.label === field_name).value;
        },
    },
})
</script>

