<template>
    <v-container id="BollettaInterattivaFornituraCostiCard5Dt">
            <v-card-title>
                <v-container>
                <v-row>
                 <v-col cols="8">
                    <v-row align="left">
                        <v-col cols="12" align="left">
                        <p class="text-h5 TitleFornituraCard5Dt">{{translateLabel('message.bolletta_interattiva_template5_costi_title')}}</p>
                        <p class="text-h5" id="consumoText">{{ item.consumi.periodo }}</p>
                        </v-col>
                    </v-row>

                    <v-row class="FirstContentBollettaFornituraCostiCard5Dt" >
                        <v-col cols="6"></v-col>
                        <v-col justify="center" align="center">
                            <p class="invoices_totale_pagare">{{$t('message.bolletta_interattiva_template5_costi_totale_da_pagare')}}</p>
                            
                            <div v-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'amount'" class="font-weight-bold px-4 doc_payment_debt_value">
                                {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatAmount(documentData.dati_pagamento_fields.doc_payment_debt.format_show, documentData.currency_sigle) }}
                            </div>
                            <div v-else-if="documentData.dati_pagamento_fields.doc_payment_debt.format == 'number'" class="font-weight-bold px-4 doc_payment_debt_value">
                                {{ documentData.dati_pagamento_fields.doc_payment_debt.value | formatNumber(documentData.dati_pagamento_fields.doc_payment_debt.format_show) }}
                            </div>
                            <div v-else class="font-weight-bold px-4 doc_payment_debt_value">
                                {{ documentData.dati_pagamento_fields.doc_payment_debt.value }}
                            </div>
                        </v-col>

                    </v-row>
                </v-col>

                 <v-col cols="4">
                            <img :src="getImg('/images/bollettainterattiva/Template5/Core/img-costi.svg')" />
                    </v-col>
                </v-row>
                </v-container>
            </v-card-title>
            <v-row class="SecondContentBollettaFornituraCard5Dt"  >
            <v-col sm="4">
            </v-col>
            <v-col sm="4" style="text-align: center;">
                <v-btn
                    icon
                    @click="openClose"
                >
                    <span class="costi_show_label" >{{show ?   $t('message.bolletta_interattiva_template5_costi_chiudi') :  $t('message.bolletta_interattiva_template5_costi_espandi')}}</span>

                    <v-icon color="#195aa2">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-col>
            <v-col sm="4">
            </v-col>
        </v-row>
            <v-expand-transition>
            <div v-show="show" id="bolletta_interattiva_storico_consumi_header">
                <div v-for="(voice, i) in item.costi" :key="'costi_voice-' + i"  :class="'costi_voice costi_voice-' + i" > 
                <v-row> 
                    <v-col cols="4">
                        <p class="voice_costi_label">{{translateLabel('message.bolletta_interattiva_template5_costi_voices.' + voice.lbl,voice.lbl)}}</p>
                    </v-col> 
                    <v-col cols="4">
                        <v-tooltip v-if="voice.tooltip" color="#195aa2" top>
                            <template v-slot:activator="{ on }">
                                <img v-on="on" width="10%" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-search.svg')" />
                            </template>
                            <span class="white--text" >{{translateLabel('message.bolletta_interattiva_template5_costi_voices_tooltip.' + voice.tooltip,voice.tooltip)}}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="4" v-if="voice.importo" >
                        <span class="voice_costi_value">{{voice.importo}}</span>
                    </v-col>
                </v-row>
                <v-row v-if="voice.importo">
                    <div class="costi-barra-container">
                        <div class="costi-barra-bg">
                        </div> 
                        <div  :style="{width: getWidthBar(voice.importo)}"  :class="(voice.importo>0) ? 'costi-barra-color-blue' : 'costi-barra-color-green'">
                        </div>
                    </div>
                </v-row>
                </div>
            </div>
            </v-expand-transition>
        

    </v-container>
</template>

<script>
//import { services } from '../scripts/services/serviceBuilder'



export default {
    name: 'BollettaInterattivaFornituraCostiCard5Dt',
    data: function() {
    return {
        showDialog: false,
        show: false,

    }},
    props: {
        item: {},
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
            type: Function,
        },
        getImg: {
            type: Function,
        },
        //show: Boolean,
    },
    computed: {

    },
    components: {
    },
    methods: {
        openClose() {
            let that = this;
            //that.$emit("openClose", that.$vnode.key);
            that.show = !that.show;
        }, 
        getWidthBar(importo){
            let perc = ( Math.abs(parseInt(importo))/(parseInt(this.documentData.dati_pagamento_fields.doc_payment_debt.value) / 100));
            if(perc > 100)
                perc = 100;
            else
                if(perc<0) 
                    perc = 0;
            return perc + '%';
        }
    },
}
</script>
