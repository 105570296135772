<template>
    <v-container id="headerContainer" flex class="pa-0 pt-4 wrapWidth headerContainer" style="height: 13vh; max-height: 18vh" > <!--wrapwidth is not good-->
        <v-row>
            <v-col align-self="start" class="col-5">
                <img v-if="imgHeader" height="100%" contain :src="getHeaderLogo()" id="logo_servizio" @error="() => {imgHeader = !imgHeader}"/>
            </v-col>
            <v-col sm="auto">
            </v-col>
        </v-row>
      </v-container>
</template>

<script>
import { services } from '../../../scripts/services/serviceBuilder'


export default ({
    components: {
        //Languages,
    },
    props:{
        getHeaderLogo: Function,
        locales: Array,
        cryptoId: {
            type: String,
            default: null
        },
        documentId: {
            type: String,
            default: null
        },
        checkLabel: {
            type: Function,
        },
        translateLabel: {
            type: Function,
        }
    },
    data() {
        return {
            bolletta_interattiva_esponi_descrizione_servizio_header:false,
            documentData:{},
            imgHeader: true,
            imgRight: false,
        };
    },
    methods: {
        // getRightLogo: function () {
        //     return "./" + location.hostname + "/images/bollettainterattiva/"+this.documentData.fatt_interattiva_data.header.logo_servizio;
        // },
        // getHeaderText: function(){
        //     let rightLogoPath = this.getRightLogo();
        //     return this.documentData.fatt_interattiva_data.header.descrizione_servizio_header_1 + "<br />" + 
        //     this.documentData.fatt_interattiva_data.header.descrizione_servizio_header_2 + 
        //     "<img height='25%' width='25%'' contain src='"+rightLogoPath+"' id='logo_descrizione_servizio'/>";
        // },
    },
    created: function () {
		var that = this;
        var requests = [];
        //let requests = that.cryptoId != null ? [services.apiCallerBollettaInterattiva.documentInfo(that.cryptoId)]: services.apiCallerBollettaInterattiva.documentInfoById(that.documentId);
        if(that.cryptoId != null)
            requests.push(services.apiCallerBollettaInterattiva.documentInfo(that.cryptoId));
        else
            requests.push(services.apiCallerBollettaInterattiva.documentInfoById(that.documentId));
        Promise.all(requests)
            .then(responses => {
                let obj = responses[0].data;
                
                that.documentData = obj;
                that.bolletta_interattiva_esponi_descrizione_servizio_header = Boolean(Number(that.documentData.bolletta_interattiva_esponi_descrizione_servizio_header));
                if(that.documentData.fatt_interattiva_data.header.logo_servizio)
                    that.imgRight = true;
            })
            .catch(error => {
                console("Errore Header: " + error);
            });
        
    }
})
</script>
