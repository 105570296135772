<template>
  <v-app>
    <v-skeleton-loader type="article" tile :loading="loading">
   
      <v-main class="main">
        <!-- <v-container class="wrapWidth"> -->
          <BollettaInterattiva5Dt :cryptoId="cryptoId" :documentId="documentId" :error="error" :translateLabel ="translateLabel" :getHeaderLogo="getHeaderLogo" :getImg="getImg"/>
        <!--/v-container-->
      </v-main>


      

        <v-container id="link-services">
          <v-row :no-gutters="true" align="center" class='link-footer'>
              <v-col >
                <v-row align="center" justify="center">
                  <v-col  align="center" justify="center">
                    <img contain :src="getAreaClienti()" id="logo_area_clienti" />
                  </v-col>
                  <v-col  align="left"> 
                      <p class="servizio_clienti">SERVIZIO CLIENTI</p> 
                      <a href="mailto:clienti@unoenergy.it" target="_blank" class="servizio_clienti_val">
                        <span>clienti@unoenergy.it</span>
                      </a>
                       <p class="reclami">RECLAMI</p> 
                       <a href="mailto:reclami@unoenergy.it" target="_blank" class="reclami_val">
                        <span>reclami@unoenergy.it</span>
                      </a>
                  </v-col>
                </v-row>
              </v-col>
              <v-col >
                <v-row align="center" justify="center">
                  <v-col   align="center" justify="center">
                    <img contain :src="getNumeroVerde()" id="logo_area_clienti" />
                  </v-col>
                  <v-col  align="left"> 
                      <p class="numero_verde">NUMERO VERDE</p>
                      <a href="TEL: 800 089 952" class="numero_verde_val"> TEL: 800 089 952</a>
                  </v-col>
                </v-row>
              </v-col>
              <v-col >
                <v-row align="center" justify="center">
                  <v-col align="center" justify="center">
                    <img contain :src="getSito()" id="logo_area_clienti" />
                  </v-col>
                  <v-col  align="left"> 
                      <a href="http://www.unoenergy.it/"
                       target="_blank" class="sito">
                       Visita il nostro sito</a>
                  </v-col>
                </v-row>
              </v-col>
               <v-col >
                <v-row align="center" justify="center">
                  <v-col   align="center" justify="center">
                    <img contain :src="getIndirizzo()" id="logo_area_clienti" />
                  </v-col>
                  <v-col  align="left"> 
                        <p class="indirizzo">
                        INDIRIZZO</p>
                        <p class="indirizzo_val">Corso Cavallotti, 30</p> 
                        <p class="indirizzo_val">18038 Sanremo IM</p>
                  </v-col>
                </v-row>
              </v-col>
          </v-row>
         </v-container>
      <!--contenitore del footer-->
      <v-footer class="footer" style="background-color: #373737;">
        <v-container justify="center">
          <v-row align="center">
           <p class="footer_info" align="center">
              Unoenergy S.p.A. - Sede Legale Via Caldera, 21 - 20153 Milano (MI) - Cap. Soc. € 50.000.000 i.v. - R.E.A. MI/19025650 - P.I. e C.F. 01368720080  Sede operativa: Corso Felice Cavallotti 30 - 18038 Sanremo (IM) - Codice Destinatario: DRLMKW7
            </p>
          </v-row>
        </v-container>
      </v-footer>
    </v-skeleton-loader>
  </v-app>
</template>
<script>
import BollettaInterattiva5Dt from "../components/BollettaInterattiva5/Desktop/BollettaInterattiva5Dt.vue";
//import Languages from "../components/Languages.vue";
import { services } from "../scripts/services/serviceBuilder";
import StoreTemplate5 from "../components/BollettaInterattiva5/Core/store/store.js";

export default {
  name: "templateBollettaInterattiva5Dt",
  components: {
    BollettaInterattiva5Dt,
    //Languages,
  },
  props: {
  
  },
  data: () => ({
    cryptoId: null,
    documentId: null,
    error: null,
    loading: true,
    locales: []
  }),
  computed: {
    isAuthenticated: function () {
      var result = this.getKeycloak(this.$parent);
      if (
        !result ||
        !Object.prototype.hasOwnProperty.call(result, "idTokenParsed")
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
      $route (to){
        this.loading = false;
        this.error = to.query.error;
        this.cryptoId = to.query.id;
        this.documentId = to.query.documentId;
      }
  },
  methods: {
    getKeycloak: function (node) {
      if (node == null) return null;
      if (node.$options.name != "App") return this.getKeycloak(node.$parent);
      return node.keycloak;
    },
    getHeaderLogo: function () {
      return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/UNOENERGY_2.svg" //"./" + location.hostname + "/images/bollettainterattiva/Logo.png";
    },
    getAreaClienti: function () {
      return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/servizio_clienti.svg";
    },
    getNumeroVerde: function () {
      return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/numero_verde.svg";
    },
    getSito: function () {
      return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/mouse.svg";
    },
    getIndirizzo: function () {
      return "./" + location.hostname + "/images/bollettainterattiva/Template5/Core/email.svg";
    },
    translateLabel(label, defaultLabel)
    {
        let that = this;
        if(typeof label === 'string')
        {
          return (label === that.$t(label))? ((defaultLabel)?defaultLabel:'') : that.$t(label)
        }
    },
    getImg(imageName) {
      return "./" + location.hostname + imageName;
    },
  },
  created: function () {
    var that = this;

    this.$store.registerModule("StoreTemplate5", StoreTemplate5);


    var requests = [];
    requests.push(services.apiCallerLocale.list());

    Promise.all(requests).then((responses) => {
      if (
        !!responses[0].data &&
        Array.isArray(responses[0].data) &&
        responses[0].data.length > 1
      ) {
        that.locales = responses[0].data;
        that.$i18n.locale = that.$locale.value;
      }
    });
  },
};
</script>
