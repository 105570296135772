<template>
     <v-container id="FornituraContainer" class="pa-7">
        <v-row class="d-flex" justify="center" align="center">
            <v-card class="card_home_container info_pagamento_dialog_card">
                <v-card-title>
                <v-row>
                    <v-col xl="12" lg="12" md="12" sm="12">
                        <v-form>
                            <v-container>
                            <v-row v-if="forniture.length > 1">
                                <v-col xl="12" lg="12" md="12" sm="12">
                                    <v-select
                                    label="Fornitura"
                                    :items="forniture"
                                    v-model="fornitura"
                                    required
                                    :item-text ="item => getItemSelectFornituraText(item)"
                                    return-object
                                    outlined
                                    @input="changedFornituraSelected"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="fornitura !== null && fornitura !== undefined">
                                <BollettaInterattivaFornituraCostiCard5Dt :item="fornitura"  :documentData="documentData" :translateLabel="translateLabel" :getImg="getImg" />
                            </v-row>
                            </v-container>
                        </v-form>
                    </v-col>
                </v-row>
                </v-card-title>
            </v-card>
        </v-row>
  </v-container>
</template>

<script>
//import { nextTick } from 'vue/types/umd';
//import { nextTick } from 'vue';

//import { services } from '../scripts/services/serviceBuilder'
import BollettaInterattivaFornituraCostiCard5Dt from './BollettaInterattivaFornituraCostiCard5Dt.vue';
export default {
    name: 'Fornitura',
    components:
    {
        BollettaInterattivaFornituraCostiCard5Dt
    },
    data: function() {
        return {
            bolletta_interattiva_dett_forn_header:false,
            forniture: {},
            fornitura: null,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
            type: Function,
        },
        getImg: {
            type: Function,
        },
    },
    computed: {

    },
    methods: {
      
        getItemSelectFornituraText: function(item){
            let result = (this.documentData.tipo_bolletta == 'GAS') ? item.tipo_punto +":"+ item.pdr +' - ' : item.tipo_punto +" "+ item.pod +' - ';
            item.indirizzo.forEach(element => {
                result += element+" - ";
            });
            result = result.slice(0, -3);
            return result;
        },    
        getSingleFornituraAddress: function(){
        let result = "";
        this.fornitura.indirizzo.forEach(element => {
                result += element+" - ";
        });
        result = result.slice(0, -3);
        return result;
        },
      changedFornituraSelected: async function(value) {
        await this.$nextTick();
        this.$root.$emit('changedFornituraSelected', value);
        },
            
    },
	created: function () {
        let that = this;
		that.bolletta_interattiva_dett_forn_header = Boolean(Number(that.documentData.bolletta_interattiva_dett_forn_header));

        
        that.forniture = that.documentData.fatt_interattiva_data.fornitura;
        that.fornitura = that.forniture[0];
	}
}
</script>
