<template>
  <v-container id="BollettaInterattivaWarning5Dt" class="pa-7">
    <v-row class="d-flex" justify="center" align="center">
      <v-card class="card_home_container">
        <v-row :class="isMobile ?  'monocol InterattivaWarning5DtMobile': 'col InterattivaWarning5Dt'" justify="center" align="center">
          <v-col class="FirstContentBollettaInterattivaWarning5Dt-flex" justify="center" align="center">
                <img width="100%" :src="getImg('/images/bollettainterattiva/Template5/Core/unoenergy_warning.jpg')" />
          </v-col>                     
          <v-col class="SecondContentBollettaInterattivaWarning5Dt d-flex" justify="center" align="center" >
              <div class="show_dialog_info">
                <p class="warningTitle" v-html='translateLabel("message.bolletta_interattiva_template5_warning_title")'></p>
                <span class="warningText" v-html='translateLabel("message.bolletta_interattiva_template5_warning")'></span>
              </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row> 
  </v-container>
</template>

<script>

export default {
    computed: { 

        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        }
    },
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
  }

}
</script>

<style>

</style>