<template>
  <v-dialog 
    transition="dialog-top-transition"
    v-model="showDialog" width="70%"> 
     <v-card  class="card_home_container webtile_dialog_card">
                  <v-card-title class="text-h6">                        
                      <v-row class="TitleDialog d-flex" justify="center" align="left" >
                        <v-col cols="1">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-foglia2.svg')" />
                        </v-col>
                        <v-col cols="9" class="">
                            <span class="dialog_title">{{translateLabel('message.bolletta_interattiva_template5_webtile_dialog_title')}}</span>
                        </v-col>
                        <v-col cols="1" class=".float-end">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-exit.svg')"  @click="closeDialog()" />
                        </v-col>
                      </v-row>
                  </v-card-title>

                <v-card-text class="text-subtitle-1 pa-5 ">

                      <v-row class="FirstTextDialog d-flex" justify="center" align="left" >
                        <v-col cols="6" class="">
                          <p class="webtile_dialog_text overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_webtile_dialog_text")'></p>
                        </v-col>
                        <v-col cols="6">
                          <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/image-bolletta-web.svg')" />
                        </v-col>
                      </v-row>
                      <v-form
                          ref="form"
                          v-model="valid"
                        >
                          <v-row >
                          <v-text-field v-bind:label="'Indirizzo email'" 
                          v-model="profiloDigitale.email" outlined
                          v-bind:prepend-inner-icon="getEmailIcon()"
                          :rules="[v=> checkEmail(v)]"
                          >
                          </v-text-field>
                      </v-row>
                      <v-row >
                          <v-text-field v-bind:label="'Conferma email'" 
                          v-model="profiloDigitale.confirmEmail" outlined
                          v-bind:prepend-inner-icon="getConfirmEmailIcon()"
                          :rules="[v=> checkConfirmEmail(v)]"
                          >
                          </v-text-field>
                      </v-row>
                      <v-row>
                        <v-checkbox
                          v-model="privacy_sel"
                          :rules="[checkPrivacy()]"
                        >
                        <template v-slot:label>
                            <span class="dialog_privacy"
                            v-html="$t('message.bolletta_interattiva_template5_webtile_dialog_privacy')"></span> 
                        </template>
                        </v-checkbox>
                      </v-row>   
                     
                      </v-form>

                      <v-row  justify="center" align="center">
                        <v-btn
                            class="dialog_web_save_button black--text"
                            @click="Save()"
                            x-large
                            color="white"    
                            >
                            {{$t('message.bolletta_interattiva_duferco_profilo_salva')}}          
                        </v-btn>
                      </v-row>

                </v-card-text>
              </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      profiloDigitale: {
             email: null,
             confirmEmail: null
        },
      privacy_sel : false,
      valid:true
  }
  },
 computed: {

  },
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
        showDialog:{
          type: Boolean,
          default: false
        }
    },
  methods:{
        closeDialog: function(){
            this.$emit('close');        
        },
        Save: function () {
          let that = this;
          that.valid = that.$refs.form.validate();
          if(that.valid && that.profiloDigitale.email.trim().length > 0 && that.profiloDigitale.confirmEmail.trim().length > 0)
            this.$emit('close');
        },
        checkEmail: function (value) {
            if (value == null || value.toString().length == 0)
                return true;
            return /^.+@.+\..+$/.test(value) || this.$t("message.bolletta_interattiva_template5_webtile_dialog_check_email");
        },
        checkConfirmEmail: function (value) {
           let that = this;
            if (value == null || value.toString().length == 0 || value.toString()==that.profiloDigitale.email)
                return true;
            return /^.+@.+\..+$/.test(value) || this.$t("message.bolletta_interattiva_template5_webtile_dialog_check_confirm_email");
        },
        checkPrivacy: function (value) {
            if (this.privacy_sel)
                return true;
            return value || this.$t("message.bolletta_interattiva_template5_webtile_dialog_check_privacy_err");
        },
        getEmailIcon: function () {
            let that = this;
            if(that.profiloDigitale.email === null || that.profiloDigitale.email.trim().length === 0)
                return "mdi-circle-outline";
            else
                return "mdi-circle";
        },
        getConfirmEmailIcon: function () {
            let that = this;
            if(that.profiloDigitale.confirmEmail === null || that.profiloDigitale.confirmEmail.trim().length === 0)
                return "mdi-circle-outline";
            else
                return "mdi-circle";
        },
  }

}
</script>

<style>

</style>