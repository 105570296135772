<template>
    <v-container id="BollettaInterattivaFornituraConsumiCard5Dt">

        <v-col align="left">
            <p class="text-h5 TitleFornituraConsumiCard5Dt">I miei consumi</p>
            <p class="text-h5" id="consumoText">{{ item.consumi.periodo }}</p>
        </v-col>

        <v-row class="FirstContentBollettaFornituraConsumiCard5Dt">
            <v-col cols="4" v-if="documentData.tipo_bolletta == 'GAS'">
            </v-col>
            <v-col cols="4" v-if="documentData.tipo_bolletta == 'GAS'" class="consumi_gas">
                <v-tooltip color="#195aa2" top>
                    <template v-slot:activator="{ on }">
                        <img v-on="on" width="10%" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-search.svg')" />
                    </template>
                    <span class="white--text" >{{$t('message.bolletta_interattiva_template5_tooltip_search')}}</span>
                </v-tooltip>
                <p class="text-h5" id="consumoText">{{ item.consumi.consumo }}</p>
                <div v-if="item.pde">
                <span class="invoices_pde_label">{{$t('message.bolletta_interattiva_template5_pde')}}</span>:&nbsp;
                <span class="invoices_pde">{{ item.pde}}</span>
                </div>
            </v-col>
            <v-col cols="4" v-if="documentData.tipo_bolletta != 'GAS'" class="consumi_luce">
                <span class="text-h5" id="consumoText">{{ item.consumi.consumo }}</span>
                <v-tooltip color="#195aa2" top>
                    <template v-slot:activator="{ on }">
                        <img v-on="on" width="10%" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-search.svg')" />
                    </template>
                    <span class="white--text" >{{$t('message.bolletta_interattiva_template5_tooltip_search')}}</span>
                </v-tooltip>
                <div v-if="item.pde">
                <span class="invoices_pde_label">{{$t('message.bolletta_interattiva_template5_pde')}}</span>:&nbsp;
                <span class="invoices_pde">{{ item.pde}}</span>
                </div>
            </v-col>
            <v-col cols="4" v-if="documentData.tipo_bolletta != 'GAS'">
            </v-col>
            <v-col cols="4">
                    <img :src="getImg('/images/bollettainterattiva/Template5/Core/img-grafico.svg')" />
            </v-col>
        </v-row>

        <v-row class="SecondContentBollettaFornituraConsumiCard5Dt" justify="left" align="left" v-if="documentData.tipo_bolletta == 'GAS'">
            <v-col cols="2" >
              <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-contatore.svg')" />
            </v-col>
            <v-col cols="10" class="">
                <span class="fornitura_consumi_text_gas" v-html="$t('message.bolletta_interattiva_template5_fornitura_consumi_text_gas')"></span>
            </v-col>
        </v-row>   
        <v-row v-else class="SecondContentBollettaFornituraConsumiCard5Dt" align="center" justify="center" >
            <span class="fornitura_consumi_text_luce" v-html="$t('message.bolletta_interattiva_template5_fornitura_consumi_text_luce')"></span>
        </v-row>   

        <v-row v-if="documentData.tipo_bolletta != 'GAS'" class="ThirdContentBollettaFornituraConsumiCardLuce5Dt" align="center" justify="center" >
            <v-col cols="4">
                <canvas id="annocorso-chart" ref="annocorso-chart"> </canvas>
            </v-col>
            <v-col cols="4">
                <v-row v-if="checkPercentageLuce()">
                    <v-col cols="4">
                        <img :src="(percentage > 0 ) ? getImg('/images/bollettainterattiva/Template5/Core/arrow-down.svg') : getImg('/images/bollettainterattiva/Template5/Core/arrow-up.svg')" />
                        <img :src="(percentage > 0 ) ? getImg('/images/bollettainterattiva/Template5/Core/ico-percent-positive.svg') : getImg('/images/bollettainterattiva/Template5/Core/ico-percent-negative.svg') " />
                    </v-col>
                    <v-col cols="8">
                        <p class="fornitura_consumi_percentage_luce" v-html="percentage + '%'"></p>
                        <p class="fornitura_consumi_percentage_luce_text">{{(percentage > 0 ) ?  translateLabel('message.fornitura_consumi_percentage_luce_text_complimenti') : translateLabel('message.fornitura_consumi_percentage_luce_text_far_meglio') }}</p>
                        <p class="fornitura_consumi_percentage_luce_text_secondario">{{(percentage > 0 ) ?  translateLabel('message.fornitura_consumi_percentage_luce_text_secondario_complimenti') : translateLabel('message.fornitura_consumi_percentage_luce_text_secondario_far_meglio') }}</p>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-col>
            <v-col cols="4">
                <canvas id="annoprecedente-chart" ref="annoprecedente-chart"> </canvas>
            </v-col>
        </v-row>   
        
        <v-row v-if="documentData.tipo_bolletta != 'GAS'" class="FourthContentBollettaFornituraConsumiCardLuce5Dt" align="center" justify="center" >
            <span class="fornitura_consumi_text_luce" v-html="$t('message.bolletta_interattiva_template5_fornitura_consumi_text_luce_info_aggiuntive')"></span>
        </v-row>   

        <v-row class="ThirdContentBollettaFornituraConsumiCardGas5Dt" justify="center" align="center" v-if="documentData.tipo_bolletta == 'GAS'" >
            <v-col cols="6"  justify="center" align="center" >
                <img :src="getImg('/images/bollettainterattiva/Template5/Core/ico-point-datea-desktop.svg')" />
                <span class="fleft">{{firstValidDay()}}</span>
                <span class="fright">{{lastValidDay()}}</span>
            </v-col>
            <v-col cols="6">
                <v-row  justify="center" align="center" >
                    <img width="5%" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-campana.svg')" />
                    <span class="download_promemoria"
                    @click="downloadCalendarMemo()">{{translateLabel('message.bolletta_interattiva_template5_fornitura_consumi_promemoria')}}</span>
                </v-row>
            </v-col>
        </v-row>  
        <v-row v-if="documentData.tipo_bolletta == 'GAS'" class="FourthContentBollettaFornituraConsumiCardGas5Dt" justify="center" align="center"  >
        <v-btn
              class="bollettaInterattiva3_downloadMemo white--text"
                @click="showDialog=true"
                x-large
                color="black"
                >
                <span class="bollettaInterattiva5_autolettura_content"
                 v-html="$t('message.bolletta_interattiva_template5_autolettura_dialog_title')"></span> 
          </v-btn>
        </v-row>

        <BollettaInterattivaAutoletturaDialog5Dt v-if="documentData.tipo_bolletta == 'GAS'" :showDialog="showDialog" @close="showDialog=false" :translateLabel="translateLabel" :getImg="getImg"/> 

                
    </v-container>
</template>

<script>
//import { services } from '../scripts/services/serviceBuilder'
import moment from "moment";
import { createEvent} from 'ics';
import BollettaInterattivaAutoletturaDialog5Dt from './BollettaInterattivaAutoletturaDialog5Dt.vue';
import Chart from "chart.js";


export default {
    name: 'BollettaInterattivaFornituraConsumiCard5Dt',
    data: function() {
    return {
        showDialog: false,
        percentage: 0,
        annoCorsoVariable: null,
        annoCorsoData: {
            type: "bar",
            data:{},
            options: {
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        boxWidth: 0,
                        usePointStyle: false
                    },
                    onClick: function() {}
                },
                scales: {
                    yAxes: [{
                        display:false
                    }],
                    xAxes: [{
                        display:'auto'
                    }],
                }    
            },
        },
        annoPrecedenteVariable: null,
        annoPrecedenteData: {
            type: "bar",
            data:{},
            options: {
                legend: {
                    display: true,
                    position: "top",
                    labels: {
                        boxWidth: 0,
                        usePointStyle: false
                    },
                    onClick: function() {}
                },
                scales: {
                    yAxes: [{
                        display:false
                    }],
                    xAxes: [{
                        display:'auto'
                    }],
                }
            },    
            },
        }
    },
    props: {
        item: {},
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
            type: Function,
        },
        getImg: {
            type: Function,
        },
        //show: Boolean,
    },
    computed: {

    },
    components: {
        BollettaInterattivaAutoletturaDialog5Dt
    },
    methods: {
    getPercentageLuce:function(){
     if(this.checkPercentageLuce())
     this.percentage = (this.item.storico_consumi_annuali[1].consumo - this.item.storico_consumi_annuali[0].consumo) / (this.item.storico_consumi_annuali[1].consumo /100);
     else
     this.percentage = 0;
    },
    checkPercentageLuce:function(){
     return (this.item.storico_consumi_annuali && this.item.storico_consumi_annuali.length > 1 && this.item.storico_consumi_annuali[0].consumo!=null  && this.item.storico_consumi_annuali[1].consumo!=null)
    },
    lastValidDay:function(){
     return moment().endOf('month').format('DD/MM');
    },
    firstValidDay:function(){
        return moment().endOf('month').subtract(5, "days").format('DD/MM');
    },
    downloadCalendarMemo: async function(){
            //Generate ICS file
            let lastDayOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
            let firstDayofNextMonth = new Date(lastDayOfCurrentMonth.valueOf());
            firstDayofNextMonth.setDate(firstDayofNextMonth.getDate() + 1);
            console.log(firstDayofNextMonth);
            let alarm = [{ action: 'display', description: 'Reminder', trigger: { hours: 24, minutes: 0, before: true } }];
            const event = {
                productId:"Duferco Energia",
                start: [lastDayOfCurrentMonth.getFullYear(), lastDayOfCurrentMonth.getMonth()+1, lastDayOfCurrentMonth.getDate(), 11, 0],
                title: "Effettua l'autolettura gas.",
                description: "Ricordati di effettuare l'autolettura gas. Hai tempo dal 25/11/2023 al 30/11/2023",
                status: 'CONFIRMED',
                recurrenceRule: 'FREQ=MONTHLY;BYMONTHDAY=-1;UNTIL='+firstDayofNextMonth.getFullYear()+firstDayofNextMonth.getMonth()+1+firstDayofNextMonth.getDate()+'T000000',
                alarms: alarm
            }
        

            const filename = 'Promemoria Autolettura.ics'
            const file = await new Promise((resolve, reject) => {
                createEvent(event, (error, value) => {
                if (error) {
                    reject(error)
                }
                
                resolve(new File([value], filename, { type: 'plain/text' }))
                })
            })
            const url = URL.createObjectURL(file);
            
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;
            
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            
            URL.revokeObjectURL(url);
        },

    // closeDialog: function(){
    //     console.log('close dialog 2');
    //     this.dialog = false;

    // },

    //Charts
    initMethod: function () {
        let firstrun= (this.annoCorsoVariable ==null && this.annoPrecedenteVariable==null);


         if(this.checkPercentageLuce() && parseInt(this.item.storico_consumi_annuali[0].anno) < parseInt(this.item.storico_consumi_annuali[1].anno))
            this.item.storico_consumi_annuali = this.item.storico_consumi_annuali.reverse();

        this.resetChartsData();

        this.populateAnnoCorsoData();
        this.populateAnnoPrecedenteData();

        if(!firstrun){
            this.annoCorsoVariable.update();
            this.annoPrecedenteVariable.update();
        }
    },
    resetChartsData: function(){
        this.annoCorsoData.data = {
                    labels:[],
                    datasets: [
                            {
                                label: '',
                                data: [],
                                backgroundColor: this.documentData.spesa_annua_color,
                                hoverOffset: 0,
                            },
                        ],
                };
        this.annoPrecedenteData.data = {
                    labels:[],
                    datasets: [
                        {
                            label : '',
                            data: [],
                            backgroundColor: this.documentData.spesa_annua_color,
                            hoverOffset: 0,
                        },
                    ],
                }; 
    },
    populateAnnoCorsoData: function(){
        let that = this;
        
        that.annoCorsoData.data.labels = [that.item.storico_consumi_annuali[0].mese  + ' ' +  that.item.storico_consumi_annuali[0].anno]
        that.annoCorsoData.data.datasets[0].data.push(that.item.storico_consumi_annuali[0].consumo);
        that.annoCorsoData.data.datasets[0].label = (that.item.storico_consumi_annuali[0].consumo + that.item.storico_consumi_annuali[0].uom);

    },
    populateAnnoPrecedenteData: function(){
        let that = this;
        that.annoPrecedenteData.data.labels = [that.item.storico_consumi_annuali[1].mese + ' ' + that.item.storico_consumi_annuali[1].anno] 
        that.annoPrecedenteData.data.datasets[0].data.push(that.item.storico_consumi_annuali[1].consumo);
        that.annoPrecedenteData.data.datasets[0].label = (that.item.storico_consumi_annuali[1].consumo + that.item.storico_consumi_annuali[1].uom);

    }
    },
    beforeMount(){
        if(this.documentData.tipo_bolletta != 'GAS')
            this.initMethod();
    },
    mounted: function()
    {
        if(this.documentData.tipo_bolletta != 'GAS')
        {

        let that = this;

        this.getPercentageLuce()
    
        that.$root.$on("changedFornituraSelected", this.initMethod);

        const ctxA = that.$refs["annocorso-chart"];
        that.annoCorsoVariable = new Chart(ctxA, that.annoCorsoData);
        const ctxB = that.$refs["annoprecedente-chart"];
        that.annoPrecedenteVariable = new Chart(ctxB, that.annoPrecedenteData);
        }
    }
}
</script>
