<template>
  <v-container id="BollettaInterattivaDownload3Dt" class="pa-7">
    <v-row justify="center">
      <v-btn
          class="bollettaInterattiva3_downloadButton white--text"
            @click="downloadFile"
            style="border-radius: 10px !important;"
            x-large
            color="orange"
            > <span class="bollettaInterattiva3_downloadButton_content" v-html="$t('message.bolletta_interattiva_download_button_label')"></span> 
      </v-btn>
    </v-row> 
  </v-container>
</template>

<script>
import { services } from "../../scripts/services/serviceBuilder";

export default {
 computed: {

  },
  props: {
        documentData: {
            type: Object,
            default: null
        }
    },
  methods:{
    downloadFile: function() {
      var that = this;

      services.apiCallerMedia.download(that.documentData.detail)
        .then(response =>  {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
        })               
        .catch(error => {
            console.log(error);
        });
      },
  }

}
</script>

<style>

</style>