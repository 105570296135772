<template>
  <v-container id="BollettaInterattivaInfoPagamento5Dt" class="pa-7">
    <v-row class="d-flex" justify="center" align="center">
      <v-card class="card_home_container">
        <v-row class="FirstContentBollettaInterattivaInfoPagamento5Dt-flex" justify="center" align="center">
          <span class="pagamento_status">{{translateLabel('message.bolletta_interattiva_template5_pagamento_status')}}</span>
        </v-row>                     
        <v-row class="SecondContentBollettaInterattivaInfoPagamento5Dt d-flex" justify="center" align="center" >
            <span class="show_dialog_info"
            @click="showDialog = true">{{translateLabel('message.bolletta_interattiva_template5_info_pagamento_button_label')}}</span>
        
        
            <!-- <v-dialog 
              transition="dialog-top-transition"
              v-model="showDialog" width="70%">
               
                <v-card class="card_home_container info_pagamento_dialog_card">
                    <v-card-title class="text-h6">                        
                       <v-row class="TitleDialog d-flex" justify="center" align="left" >
                          <v-col cols="1">
                            <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-info-sul-opagamento.svg')" />
                          </v-col>
                          <v-col cols="9" class="">
                              <span class="dialog_title">{{translateLabel('message.bolletta_interattiva_template5_info_pagamento_dialog_title')}}</span>
                          </v-col>
                          <v-col cols="1" class=".float-end">
                            <img class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-exit.svg')"  @click="showDialog = false" />
                          </v-col>
                        </v-row>
                    </v-card-title>

                  <v-card-text class="text-subtitle-1 pa-5 ">
                      <p class="info_pagamento_dialog_text overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_info_pagamento_dialog_text")'></p>
                  </v-card-text>
                </v-card>
            </v-dialog> -->
        </v-row>

        <BollettaInterattivaInfoPagamentoDialog5Dt :showDialog="showDialog" @close="showDialog=false" :translateLabel="translateLabel" :getImg="getImg"/> 

        
      </v-card>
    </v-row> 
  </v-container>
</template>

<script>
import { services } from '../../../scripts/services/serviceBuilder'
import BollettaInterattivaInfoPagamentoDialog5Dt from './BollettaInterattivaInfoPagamentoDialog5Dt.vue';

export default {
 components: {
  BollettaInterattivaInfoPagamentoDialog5Dt
  },
  data: function() {
    return {
    showDialog: false,
    }},
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
    downloadFile: function() {
      var that = this;

      services.apiCallerMedia.download(that.documentData.detail)
        .then(response =>  {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
        })               
        .catch(error => {
            console.log(error);
        });
      },
  }

}
</script>

<style>

</style>